import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Shared/Sidebar'
import Stats from './Stats';
import Events from './Events';
import ManageEvents from './ManageEvents';
import Users from './Users';
import Roles from './Roles';
import Settings from './Settings';

const Dashboard = () => {
  const [items, setItems] = useState([
    { name: 'Dashboard', page: <Stats/> },
    { name: 'Events', page: <ManageEvents/> },
    { name: 'Users', page: <Users /> },
    { name: 'Roles', page: <Roles /> },
    { name: 'Settings', page: <Settings /> },
  ])
  const [mainContent, setMainContent] = useState('Dashboard');
  const [content, setContent] = useState(<Stats/>);
  //state for main content
  useEffect(() => {
    setContent(items.filter(item => item.name === mainContent)[0].page)
  },[mainContent])

  return (
    <div className='w-full  h-screen'>
      {/* sidebar */}
      <div className='flex flex-row'>
        <div className='flex flex-col pt-10 border min-w-fit min-h-screen bg-gray-900'>
          {items && <Sidebar items={items} setItem={setMainContent}/>}
        </div>
        <div className='flex flex-col  border w-full  bg-blue-500'>
         {mainContent && <h1 className='text-white text-3xl text-center pt-10 mt-10'>{content}</h1>} 
        </div>
      </div>
    </div>
  )
}

export default Dashboard