import React from 'react'

const CorporateEventCard = (data) => {
  return (
        <div class="feature-card">
            <div class="feature-icon">{data.icon}</div>
            <h3 class="feature-title">{data.title}</h3>
            <p class="feature-description"> {data.description}</p>
            <img src={data.url} alt="Stunning beach resort at sunset, aerial view" class="feature-image" width="300" height="200" />
          </div>
  )
}

export default CorporateEventCard