import { MenuButton } from '@chakra-ui/react'
import React , {useState} from 'react'

const Stats = () => {
  const [tabsDash, setTabDash] = useState([
    {name: 'Analytics', active: true},  
    { name: 'Sales', icon: <MenuButton/>, active: false},
    { name: 'Orders', icon: <MenuButton/>, active: false},
    { name: 'Customers', icon: <MenuButton/>,active: false},
    { name: 'Products', icon: <MenuButton/>,active: false},
    { name: 'Marketing', icon: <MenuButton/>,active: false},
    { name: 'Inventory', icon: <MenuButton/>,active: false},
    { name: 'Reports', icon: <MenuButton/>,active: false},
    { name: 'Settings', icon: <MenuButton/>,active: false},
  ])
  const [currentTab, setCurrentTab] = useState('Analytics')

  const setActive = (name) => {
    var newTabs = [...tabsDash]
    setCurrentTab(name)
    newTabs.map((tab,index) => {
      if(tab.name === name){
        tab.active = true
      }else{
        tab.active = false
      }
    })
    setTabDash(newTabs)
  }

  return (
   <div className='w-full min-h-screen mt-0 flex flex-col'>
      <div className='w-full text-white flex flex-row'>
          <h1 className='text-3xl text-center justify-start mx-5 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </h1>
          <div className='flex justify-end flex-row w-full'>
            {tabsDash.map((tab, index) => (
              <div key={index} className={`flex flex-row justify-center items-center mx-5 ${tab.active? 'bg-black':''}`}>
                {/* <h1 className='text-2xl'>{tab.icon}</h1> */}
                <button value={tab.name} className='text-sm' onClick={(e)=> setActive(e.target.value)}>{tab.name}</button>
              </div>
            ))}
          </div>
      </div>
      <div className='flex '>
          <graph className='w-1/2 h-1/2 bg-blue-500'>
            <h1>{currentTab}</h1>
          </graph>
      </div>
  </div>
  )
}

export default Stats