import React  from 'react'
const Sidebar = (data) => {
  const items=data.items
  const setItem=data.setItem
  return (
    <div className='w-full'>
        <div className='flex flex-col pt-10 w-44 inset-0 text-white px-5 overflow-x-auto'>
            <ul>
                {items.map((item, index) => {
                    return <li key={index} onClick={()=>setItem(item.name)}>{item.name}
                    </li>
                }
                )}
            </ul>
        </div>
    </div>
  )
}

export default Sidebar