import React from 'react'
import Footer from '../components/Shared/Footer'

const WeddingConsultation = () => {
  return (
    <body>
    <main>
    <section class="hero-mc">
      <div class="hero-mc-content">
        <h1>Wedding Consultation</h1>
        <p>Let's bring your dream wedding to life</p>
      </div>
    </section>

    <section class="consultation-form-mc">
      <div class="container-mc">
        <div class="form-container-mc">
          <h2>Schedule Your Consultation</h2>
          <form id="weddingConsultationForm" action="https://events.thevillalife.com/api/wedding-consultation" method="POST">
            <div class="form-group-mc">
              <label for="names">Your Names</label>
              <input type="text" id="names" name="names" required/>
            </div>
            <div class="form-group-mc">
              <label for="email">Email Address</label>
              <input type="email" id="email" name="email" required/>
            </div>
            <div class="form-group-mc">
              <label for="phone">Phone Number</label>
              <input type="tel" id="phone" name="phone" required/>
            </div>
            <div class="form-group-mc">
              <label for="weddingDate">Preferred Wedding Date</label>
              <input type="date" id="weddingDate" name="weddingDate" required/>
            </div>
            <div class="form-group-mc">
              <label for="guestCount">Estimated Guest Count</label>
              <select id="guestCount" name="guestCount" required>
                <option value="">Select an option</option>
                <option value="0-50">0-50 guests</option>
                <option value="51-100">51-100 guests</option>
                <option value="101-150">101-150 guests</option>
                <option value="151-200">151-200 guests</option>
                <option value="200+">200+ guests</option>
              </select>
            </div>
            <div class="form-group-mc">
              <label for="venue">Preferred Venue Type</label>
              <select id="venue" name="venue" required>
                <option value="">Select an option</option>
                <option value="beach">Beach</option>
                <option value="garden">Garden</option>
                <option value="ballroom">Ballroom</option>
                <option value="historic">Historic Site</option>
                <option value="destination">Destination Wedding</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="form-group-mc">
              <label for="message">Tell us about your dream wedding</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" class="submit-btn">Request Consultation</button>
          </form>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</body>
  )
}

export default WeddingConsultation