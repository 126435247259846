import React from 'react'
import Footer from '../components/Shared/Footer'

const Events = () => {
  const events=[{
    url:'conferenceHall.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },
  {
    url:'gathering.avif',
    date:'August 19, 2023',
    title:'VillaLife Pool Party',
    description:'Celebrate summer with a luxurious pool party at the VillaLife Mansion. Enjoy an afternoon of music, drinks, and networking with industry professionals.',
    link:'/events/villalife-pool-party'
  },
  {
    url:'gathering1.avif',
    date:'September 23, 2023',
    title:'Beach Retreat Weekend',
    description:'Relax and recharge at the VillaLife Beach Retreat. Spend the weekend in a luxurious beachfront villa, enjoy gourmet meals, and connect with other luxury rental professionals.',
    link:'/events/beach-retreat-weekend'
  },
  {
    url:'gathering2.avif',
    date:'December 15, 2023',
    title:'Holiday Gala Dinner',
    description:'Celebrate the holiday season in style at the VillaLife Holiday Gala Dinner. Enjoy an evening of fine dining, entertainment, and festive cheer with industry colleagues.',
    link:'/events/holiday-gala-dinner'
  },
  {
    url:'presentation.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },
  {
    url:'presentation1.avif',
    date:'August 19, 2023',
    title:'VillaLife Pool Party',
    description:'Celebrate summer with a luxurious pool party at the VillaLife Mansion. Enjoy an afternoon of music, drinks, and networking with industry professionals.',
    link:'/events/villalife-pool-party'
  },
  {
    url:'presentation2.avif',
    date:'September 23, 2023',
    title:'Beach Retreat Weekend',
    description:'Relax and recharge at the VillaLife Beach Retreat. Spend the weekend in a luxurious beachfront villa, enjoy gourmet meals, and connect with other luxury rental professionals.',
    link:'/events/beach-retreat-weekend'
  },
  {
    url:'holidayGala.avif',
    date:'December 15, 2023',
    title:'Holiday Gala Dinner',
    description:'Celebrate the holiday season in style at the VillaLife Holiday Gala Dinner. Enjoy an evening of fine dining, entertainment, and festive cheer with industry colleagues.',
    link:'/events/holiday-gala-dinner'
  },
  {
    url:'conferenceHall.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },

]
  return (
    <body>
  <main>
    <section className="hero">
      <div class="hero-content">
        <h1>Luxury Experiences & Event Planning</h1>
        <p>Creating unforgettable moments in breathtaking locations</p>
        <a href="/contact" class="cta-button">Start Planning</a>
      </div>
    </section>

    <section class="services">
      <div class="container">
        <h2>Our Services</h2>
        <div class="services-grid">
          <div class="service-card">
            <img src="service1.avif" alt="Elegant wedding setup on a beach" width="400" height="200"/>
            <div class="service-content">
              <h3>Weddings</h3>
              <p>Your dream wedding brought to life in paradise</p>
            </div>
          </div>
          <div class="service-card">
            <img src="service2.avif" alt="Corporate retreat setting with ocean view" width="400" height="200"/>
            <div class="service-content">
              <h3>Corporate Retreats</h3>
              <p>Inspiring locations for team building and strategy</p>
            </div>
          </div>
          <div class="service-card">
            <img src="service3.avif" alt="Luxurious event setup with candles and flowers" width="400" height="200"/>
            <div class="service-content">
              <h3>Special Events</h3>
              <p>Bespoke celebrations for life's milestones</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container">
        <div class="about-content">
          <div class="about-text">
            <h2>About The Villa Life</h2>
            <p>At The Villa Life, we’re all about turning moments into memories. With a deep passion for luxury hospitality and event planning, we don’t just create events – we craft experiences that leave a lasting impact. Whether you’re dreaming of a romantic beach wedding, a corporate getaway at a stunning villa, or a milestone celebration, we bring your vision to life with care and precision.
            </p>
            <br/>
            <p>What sets us apart is our obsession with the details. From our handpicked collection of world-class venues to our trusted network of top-tier vendors, we’re committed to going above and beyond to exceed your expectations. Let us help you create unforgettable moments that will stay with you forever.</p>
          </div>
          <div class="about-image">
            <img src="aboutus.avif" alt="Luxury event planning team in action" width="600" height="400"/>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials">
      <div class="container">
        <h2>What Our Clients Say</h2>
        <div class="testimonial-slider">
          <div class="testimonial">
            <p>"The Villa Life made our destination wedding absolutely perfect. Every detail was taken care of, and our guests are still talking about it months later!"</p>
            <p class="testimonial-author">- Sarah & Michael</p>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</body>
  )
}

export default Events