import React from 'react'

const Preferences = () => {
  return (
    <div className='w-full bg-blue-600 h-screen'>
    <h1 className='text-white text-3xl text-center pt-10 mt-10'>Prefernces</h1>
  </div>
  )
}

export default Preferences