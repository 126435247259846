import { useState } from 'react';
import Dashboard from './pages/Dashboard';
import Preferences from './pages/Preferences';
import Login from './components/Login/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useToken from './components/Hooks/useToken';
import Events from './pages/Events';
import Header from './components/Shared/Header';
import CorporateEvents from './pages/CorporateEvents';
import ContactUs from './pages/ContactUs';
import Weddings from './pages/Weddings';
import WeddingConsultation from './pages/WeddingConsultation';
import EventDetails from './pages/EventDetails';

function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}
  
function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}

function App() {
  const { token, setToken } = useToken();
  

  const getItem = (item) => {
      if(!token) {
      return <Login setToken={setToken} />
    }
    switch (item) {
      case "dashboard":
        return <Dashboard />
      case "preferences":
        return <Preferences />
      default:
        return <Login setToken={setToken}/>
    }
  }
      

  return (
    <div className="App">
      <div className="wrapper grid grid-flow-col">
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Events />} />
            <Route path="/corporate-retreats" element={<CorporateEvents />} />
            <Route path="/weddings" element={<Weddings />} />
            <Route path="/weddings/consult" element={<WeddingConsultation />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/events/:slug" element={<EventDetails  />} />
            <Route path="/dashboard" element={getItem("dashboard") } />
            <Route path="/preferences" element={getItem("preferences")} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
