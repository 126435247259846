import React from 'react'
import { useParams } from 'react-router-dom';

const EventDetails = () => {
    
  const { slug } = useParams();
  return (
    <body>
    <main>
    <section class="hero-event-details">
      <div class="hero-content-event-details">
        <h1>Luxury Rental Summit 2023 - {slug}</h1>
        <p>Join industry leaders for a day of insights, networking, and innovation in the luxury rental market</p>
        <a href="#register" class="cta-button">Register Now</a>
      </div>
    </section>
    
    <section class="event-details">
      <div class="container">
        <div class="event-grid">
          <div class="event-info">
            <h2>Event Details</h2>
            <p><strong>Date:</strong> July 15, 2023</p>
            <p><strong>Time:</strong> 9:00 AM - 6:00 PM</p>
            <p><strong>Location:</strong> Grand Luxury Hotel, New York City</p>
            <p><strong>Price:</strong> $499 (Early Bird: $399 until June 15)</p>
            <p>The Luxury Rental Summit 2023 brings together the brightest minds in the luxury rental industry. Discover the latest trends, strategies, and technologies that are shaping the future of high-end rentals. Network with fellow professionals, gain invaluable insights from industry experts, and elevate your business to new heights.</p>
          </div>
          <div class="event-schedule">
            <h3>Event Schedule</h3>
            <div class="schedule-item">
              <p class="schedule-time">9:00 AM - 10:00 AM</p>
              <p>Registration and Networking Breakfast</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">10:00 AM - 11:30 AM</p>
              <p>Keynote: The Future of Luxury Rentals</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">11:45 AM - 1:00 PM</p>
              <p>Panel Discussion: Leveraging Technology in Luxury Rentals</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">1:00 PM - 2:00 PM</p>
              <p>Networking Lunch</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">2:00 PM - 3:15 PM</p>
              <p>Workshop: Creating Unforgettable Guest Experiences</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">3:30 PM - 4:45 PM</p>
              <p>Panel Discussion: Sustainability in Luxury Rentals</p>
            </div>
            <div class="schedule-item">
              <p class="schedule-time">5:00 PM - 6:00 PM</p>
              <p>Closing Remarks and Networking Reception</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section class="speakers-section">
      <div class="container">
        <h2 className='w-full flex justify-center text-4xl py-10'>Featured Speakers</h2>
        <div class="speakers-grid">
          <div class="speaker-card">
            <img src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80" alt="Emily Thompson, CEO of LuxStay" class="speaker-image"/>
            <div class="speaker-info">
              <h3 class="speaker-name">Emily Thompson</h3>
              <p class="speaker-title">CEO, LuxStay</p>
              <p>Emily will share insights on scaling luxury rental businesses in the digital age.</p>
            </div>
          </div>
          <div class="speaker-card">
            <img src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="Alexander Chen, Hospitality Tech Expert" class="speaker-image"/>
            <div class="speaker-info">
              <h3 class="speaker-name">Alexander Chen</h3>
              <p class="speaker-title">Hospitality Tech Expert</p>
              <p>Alexander will discuss the latest tech trends revolutionizing the luxury rental industry.</p>
            </div>
          </div>
          <div class="speaker-card">
            <img src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="Sophia Rodriguez, Sustainability Consultant" class="speaker-image"/>
            <div class="speaker-info">
              <h3 class="speaker-name">Sophia Rodriguez</h3>
              <p class="speaker-title">Sustainability Consultant</p>
              <p>Sophia will present strategies for implementing eco-friendly practices in luxury rentals.</p>
            </div>
          </div>
          <div class="speaker-card">
            <img src="https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="Marcus Lee, Customer Experience Guru" class="speaker-image"/>
            <div class="speaker-info">
              <h3 class="speaker-name">Marcus Lee</h3>
              <p class="speaker-title">Customer Experience Guru</p>
              <p>Marcus will lead a workshop on creating unforgettable experiences for luxury rental guests.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section id="register" className="register-section">
      <div className="container mb-10">
        <h2 className='mt-5 font-bold text-xl'>Register Now</h2>
        <p className='mb-5'>Secure your spot at the Luxury Rental Summit 2023 and take your business to the next level.</p>
        <a href="/events/luxury-rental-summit-2023/register" class="cta-button">Register for the Event</a>
      </div>
    </section>
  </main>
  <footer className="footer">
  <div className="container">
    <p>&copy; 2024 Thevillalife. All rights reserved.</p>
    <p>
      <a href="/privacy">Privacy Policy</a> | 
      <a href="/terms">Terms of Service</a> |
      <a href="/contact">Contact Us</a>
    </p>
  </div>
</footer>
</body>
  )
}

export default EventDetails